<template>
  <div class="website-editor-container">
    <div class="website-editor-side-bar">
      <BaseButton
        :disabled="editedEqualsOriginal || updating"
        @buttonPressed="updateMenuboek"
      >
        <span v-if="updating"> Updating... </span>
        <span v-else-if="editedEqualsOriginal || isFetchingMenuboek"
          >Up-to-date</span
        >
        <span v-else>Update</span>
      </BaseButton>
    </div>
    <div class="website-editor-content">
      <div v-if="isFetchingMenuboek">Loading...</div>
      <div v-else>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { mapGetters } from "vuex";

export default {
  name: "WebsiteEditor",
  components: {
    BaseButton,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
    }),
  },
  async created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.website-editor-container {
  display: grid;
  grid-template-columns: 240px auto;
  overflow: hidden;
  height: 100%;

  .website-editor-side-bar {
    border-right: 1px solid $lm-divider-color;
    margin-top: calc(#{$standard-margin}* 2);

    button {
      margin-bottom: $standard-margin;
    }
  }

  .website-editor-content {
    margin-left: calc(#{$standard-margin}* 2);
    overflow: hidden;

    div {
      height: 100%;
    }
  }
}

.icon {
  font-size: 1.4rem;
  color: $lm-text-color;
}
</style>
