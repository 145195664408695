<template>
  <button
    :class="[{ disabled: disabled }]"
    :disabled="disabled"
    v-on:click="$emit('buttonPressed')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  emits: ["buttonPressed"],
  props: {
    size: {
      type: String,
      default: "medium-size",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    paddingSize: {
      type: String,
      default: "padding-normal",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
button {
  border: none;
  text-align: center;
  width: 100%;
  border-radius: $standard-radius;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: $standard-padding;
  padding-right: $standard-padding;
  background-color: $lm-button-background-color;
  color: $lm-button-text-color;
  height: 50px;
  width: 200px;
}

.disabled {
  background-color: $lm-button-disabled-background-color;
  color: $lm-button-disabled-text-color;
  cursor: default;
}
</style>
